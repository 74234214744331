import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { isEmail, isEmpty } from 'validator'

import { useAuthentication } from '@/api/api-hooks/auth'
import Meta from '@/components/common/Meta'
import Promotion from '@/components/sections/Promotion'
import LoginForm from '@/components/subComponents/auth/loginForm'
import * as actionTypes from '@/store/constants/actionTypes'
import { Routes } from '@/v1/constants/routes'
import { META_CONTENTS } from '@/v1/constants/seo'
import { LoginSrOnly } from '@/v1/sr-only'

function Login(props) {
  const { login } = useAuthentication()
  const [errors, setErrors] = useState({})
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  })

  const router = useRouter()

  useEffect(() => {
    if (props.Auth_Reducer.auth && router.isReady) {
      router.push(Routes.DASHBOARD).then()
    }
  }, [props.Auth_Reducer])

  const inputOnChangeHandler = e => {
    let name = e.target.name
    let value = e.target.value

    if (e.target.type === 'checkbox') {
      value = e.target.checked
      setFormData({
        ...formData,
        [name]: value === true ? 1 : 0
      })
    } else {
      setFormData({
        ...formData,
        [name]: value
      })
    }
  }

  const formValidation = () => {
    let errors = {},
      data = formData

    if (isEmpty(formData.email)) {
      errors.email = ['Email is required']
    } else if (!isEmail(data.email)) {
      errors.email = ['Invalid email format']
    }

    if (isEmpty(data.password)) {
      errors.password = ['Password is required']
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors)
    } else {
      return true
    }

    return false
  }

  const formOnSubmitHandler = async e => {
    e.preventDefault()
    let validate = formValidation()

    if (validate === true) {
      /*login.mutateAsync({ payload: formData }).then(res => {
        if (res.statusCode === StatusCode.OK) {
          router.push(Routes.DASHBOARD).then()
        }

        console.log({ res })
      })*/
      await props.onLogin(formData)
    }
  }

  return (
    <>
      <Meta
        title={META_CONTENTS.login.title}
        keywords={META_CONTENTS.login.keywords}
        description={META_CONTENTS.login.description}
        noIndex
        noFollow
      />

      <LoginSrOnly />

      <section className='login-section section-spacing--small'>
        <div className='container'>
          <div className={'max-1170'}>
            <LoginForm
              isLoading={login.isPending}
              Auth_Reducer={props.Auth_Reducer}
              formData={formData}
              formErrors={errors}
              inputOnChangeHandler={inputOnChangeHandler}
              formOnSubmitHandler={formOnSubmitHandler}
            />
          </div>
        </div>
      </section>
      <Promotion />
    </>
  )
}

const mapStateToProps = state => {
  return {
    Auth_Reducer: state.authReducer
  }
}
const mapDispatchToProps = dispatch => ({
  onLogin: data => dispatch({ type: actionTypes.USER_LOGIN, creds: data })
})
export default connect(mapStateToProps, mapDispatchToProps)(Login)
