import Link from 'next/link'
import { cn } from '@/lib/utils'
import { assets } from '@/helpers/common'

const Promotion = function ({ className, children }) {
  return (
    <section className={cn('promotion-section', className)}>
      <div className='container mx-auto'>
        <div className='row justify-center'>
          <div className='promotion-box'>
            {!children ? (
              <>
                <img className='sm-mx:hidden md:block' src={assets('images/promotion-bg.png')} alt={''} />
                <div className='promotion-inner flex justify-center items-center'>
                  <div className='promotion-content inline-block text-center'>
                    <h2>
                      Ship & Storage space on Demand! <br />
                      Are You Ready ?
                    </h2>
                    <p className='mb-5'>
                      We pick up your belongings, store them securely, and deliver them back <br />
                      to you whenever needed.
                    </p>
                    <Link href={`/book-storage-facility`} className='btn btn-primary'>
                      Get Started
                    </Link>
                    <p className='text-primary-darkblue text-sm13 font-medium'>
                      A valet storage experience that you will love it
                    </p>
                  </div>
                </div>
              </>
            ) : (
              children
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Promotion
