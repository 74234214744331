import React from 'react'

export function ContactSrOnly() {
  return (
    <div className={'sr-only'}>
      <h1>Contact Us for Shipping and Storage Services</h1>
      <h2>Get in Touch with Our Shipping and Storage Experts</h2>
      <h3>International Transit Storage and Shipping Assistance</h3>
      <h4>Contact Club Smart Storage for Shipping and Storage Solutions</h4>
      <h5>Inquire About Shipping to Storage Units</h5>
      <h6>Reach Out for Shipping Box Storage and Delivery Services</h6>
      <p>
        Keywords: ship and storage, ship and store, ship to storage, ship to storage facility, ship storage,
        international transit storage, shipping and storage services, shipping and storage service, store and ship,
        storage and ship, ship to storage unit, shipping and storage, storage shipping, storage and shipping services,
        package storage service, shipping storage, ships storage, shipping organizer, club smart storage, club smart
        self storage, shipping to storage unit, shipping box storage, delivery and storage, box world shipping center,
        worldwide storage, ship2storage, contact, contact us, contact ship and storage, ship and storage contact number,
        ship and storage email
      </p>
    </div>
  )
}
