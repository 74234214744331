import React from 'react'

export function RegisterSrOnly() {
  return (
    <div className={'sr-only'}>
      <h1>Register for Shipping and Storage Services</h1>
      <h2>Create an Account to Ship and Store Your Packages</h2>
      <h3>International Transit Storage and Shipping Registration</h3>
      <h4>Join Club Smart Storage for Shipping and Storage</h4>
      <h5>Register for Shipping to Storage Unit Services</h5>
      <h6>Sign Up for Secure Package Storage and Shipping</h6>
      <p>
        Keywords: ship and storage, ship and store, ship to storage, ship to storage facility, ship storage,
        international transit storage, shipping and storage services, shipping and storage service, store and ship,
        storage and ship, ship to storage unit, shipping and storage, storage shipping, storage and shipping services,
        package storage service, shipping storage, ships storage, shipping organizer, club smart storage, club smart
        self storage, shipping to storage unit, shipping box storage, delivery and storage, box world shipping center,
        worldwide storage, ship2storage, register, sign up
      </p>
    </div>
  )
}
