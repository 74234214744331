import { useMutation } from '@tanstack/react-query'

import ApiRoutes from '@/api/ApiRoutes'
import sendRequest from '@/api/sendRequest'
import { Routes } from '@/v1/constants/routes'
import { removeCookies } from '@/v1/lib/cookie'
import { RequestPayloads } from '@/types/global'
import CookieKeys from '@/configs/caching/keys/cookie'
import GoToDirectory from '@/components/common/GoToDirectory'

export const useAuthentication = () => {
  const login = useMutation({
    mutationKey: ['loginToTheSystem'],
    mutationFn: ({ payload, queryParams, willRedirect = false }: RequestPayloads) => {
      return sendRequest({
        method: 'POST',
        url: ApiRoutes.login,
        payload,
        queryParams,
        willRedirect
      })
    }
  })

  const logout = useMutation({
    mutationKey: ['logoutFromTheSystem'],
    mutationFn: ({ payload, queryParams }: RequestPayloads) => {
      return sendRequest({
        method: 'POST',
        url: ApiRoutes.logout,
        payload,
        queryParams
      })
    },
    onSuccess: () => {
      removeCookies([CookieKeys.accessToken, CookieKeys.adminInfo])
      GoToDirectory(Routes.LOGIN)
    }
  })

  const reset = useMutation({
    mutationKey: ['resetPassword'],
    mutationFn: ({ payload }: RequestPayloads) => {
      return sendRequest({
        method: 'POST',
        url: ApiRoutes.resetPass,
        payload
      })
    }
  })

  return {
    login,
    reset,
    logout
  }
}
