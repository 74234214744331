import React, { useState } from 'react'
import Link from 'next/link'
import { isEmpty } from 'lodash'

import { assets } from '@/helpers/common'
import Icon from '@/@core/components/icon'
import ErrorBox from '@/components/subComponents/errorBox/'
import { PrimaryButton } from '@/v1/components/ui'

const LoginForm = function ({ formData, isLoading, ...props }) {
  const [showPassword, setShowPassword] = useState(false)

  function getUnknownErrors() {
    if (
      props.Auth_Reducer.loginSetError &&
      !props.formErrors.status &&
      !isEmpty(props.Auth_Reducer.loginErrors.message)
    )
      return <ErrorBox message={props.Auth_Reducer.loginErrors.message} />
  }

  return (
    <div className='login-form-box w-full py-10 px-16 xs:py-5 xs:px-5 shadow-2xl'>
      <form className='row' onSubmit={e => props.formOnSubmitHandler(e)}>
        <div className='form-left w-4/12 md-mx:w-full'>
          <div className='section-title'>
            <h2>Welcome Back</h2>
            <p>Please login to stay connected with us</p>
          </div>
          {getUnknownErrors()}
          <div className='form-fields'>
            <div className={props.formErrors?.email ? 'input-group has-error' : 'input-group'}>
              <input
                type='text'
                name='email'
                className='input-field px-2'
                placeholder='Email Address'
                value={formData.email}
                autoComplete='off'
                onChange={e => props.inputOnChangeHandler(e)}
              />
              {props.formErrors?.email ? <span className='error'>{props.formErrors.email}</span> : ''}
            </div>
            <div className={props.formErrors?.password ? 'input-group has-error' : 'input-group'}>
              <input
                type={showPassword ? 'text' : 'password'}
                name='password'
                className='input-field px-2'
                placeholder='Password'
                value={formData.password}
                onChange={e => props.inputOnChangeHandler(e)}
              />

              <span className='text-view z-50 w-auto h-auto' onClick={() => setShowPassword(!showPassword)}>
                <Icon icon='mdi:eye' fontSize={22} />
              </span>

              {props.formErrors?.password ? <span className='error'>{props.formErrors.password}</span> : ''}
            </div>
            <div className='input-group'>
              <Link href='/reset-password' className='text-secondary underline'>
                Forgot Password?
              </Link>
            </div>
            <div className='input-group'>
              <PrimaryButton
                type='submit'
                variant={'primary'}
                loading={isLoading}
                className='w-full uppercase font-bold text-base h-12'
              >
                Login
              </PrimaryButton>
            </div>
            {!!props.Auth_Reducer.loginLoading && (
              <div className='loading'>
                <img src={assets('images/loading.svg')} alt={''} />
              </div>
            )}
          </div>
          <div className='input-group'>
            <p className=' text-sm15 text-primary mt-3'>
              New User
              <Link href='/register' className='ml-1 text-lg font-medium text-secondary underline'>
                Sign up
              </Link>
            </p>
          </div>
        </div>
        <div className='form-right w-8/12 md-mx:hidden text-right'>
          <img className='inline-block' src={assets('images/login-image.png')} alt='login' />
        </div>
      </form>
    </div>
  )
}

export default LoginForm
