import React from 'react'

export function LoginSrOnly() {
  return (
    <div className={'sr-only'}>
      <h1>Secure Login for Shipping and Storage Services</h1>
      <h2>Access Your Account to Ship and Store with Ease</h2>
      <h3>International Transit Storage and Shipping Made Simple</h3>
      <h4>Login to Manage Your Storage Shipping</h4>
      <h5>Secure Storage and Ship to Storage Unit Options</h5>
      <h6>Club Smart Self Storage and Shipping Organizer</h6>
      <p>
        Keywords: ship and storage, ship and store, ship to storage, ship to storage facility, ship storage,
        international transit storage, shipping and storage services, shipping and storage service, store and ship,
        storage and ship, ship to storage unit, shipping and storage, storage shipping, storage and shipping services,
        package storage service, shipping storage, ships storage, shipping organizer, club smart storage, club smart
        self storage, shipping to storage unit, shipping box storage, delivery and storage, box world shipping center,
        worldwide storage, ship2storage
      </p>
    </div>
  )
}
