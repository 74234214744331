import React from 'react'

export function ResetPasswordSrOnly() {
  return (
    <div className={'sr-only'}>
      <h1>Reset Your Password for Shipping and Storage</h1>
      <h2>Secure Your Shipping and Storage Account</h2>
      <h3>International Transit Storage and Shipping Account Recovery</h3>
      <h4>Reset Password to Continue Using Shipping and Storage Services</h4>
      <h5>Manage Your Club Smart Self Storage Access</h5>
      <h6>Reset Password for Ship to Storage Unit Services</h6>
      <p>
        Keywords: ship and storage, ship and store, ship to storage, ship to storage facility, ship storage,
        international transit storage, shipping and storage services, shipping and storage service, store and ship,
        storage and ship, ship to storage unit, shipping and storage, storage shipping, storage and shipping services,
        package storage service, shipping storage, ships storage, shipping organizer, club smart storage, club smart
        self storage, shipping to storage unit, shipping box storage, delivery and storage, box world shipping center,
        worldwide storage, ship2storage, forgot password, reset password
      </p>
    </div>
  )
}
