const ApiRoutes = {
  /* GET */
  states: `states`,
  countries: `countries`,
  userProfile: `auth/user-profile`,

  /* POST */
  login: `login`,
  logout: `auth/logout`,
  forgotPass: `change-password`,
  // forgotPass: `change-password?token=`
  resetPass: `reset-password-request`

  /* PUT */

  /* DELETE */
}

export default ApiRoutes
